<template>
    <div class="argusStatistickDashboard">
        <div class="argusStatistickDashboard__header">
            <div class="argusStatistickDashboard__title">Argus statistic</div>
        </div>
        <div class="argusStatisticRow__header">
            <div class="argusStatisticRow__header-item">{{new Date().getFullYear()}}</div>
            <div class="argusStatisticRow__header-item">{{new Date().getFullYear() - 1}}</div>
            <div class="argusStatisticRow__header-item">2019</div>
        </div>
        <div class="argusStatisticRow__row">
            <input
              class="argusStatisticRow__title"
              type="text"
              v-model="yearToDateTitle"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="yearToDateCurrentYearValue"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="yearToDatePreviousYearValue"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="yearToDateYearBeforePreviousValue"
              autocomplete="off"
            />
        </div>
        <div class="argusStatisticRow__row">
            <input
              class="argusStatisticRow__title"
              type="text"
              v-model="weeklyTitle"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="weeklyCurrentYearValue"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="weeklyPreviousYearValue"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="weeklyYearBeforePreviousValue"
              autocomplete="off"
            />
        </div>
        <div class="argusStatisticRow__row">
            <input
              class="argusStatisticRow__title"
              type="text"
              v-model="firstMonthTitle"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="firstMonthCurrentYearValue"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="firstMonthPreviousYearValue"
              autocomplete="off"
            />
        </div>
        <div class="argusStatisticRow__row">
            <input
              class="argusStatisticRow__title"
              type="text"
              v-model="secondMonthTitle"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="secondMonthCurrentYearValue"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="secondMonthPreviousYearValue"
              autocomplete="off"
            />
        </div>
        <div class="argusStatisticRow__row">
            <input
              class="argusStatisticRow__title"
              type="text"
              v-model="thirdMonthTitle"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="thirdMonthCurrentYearValue"
              autocomplete="off"
            />
            <input
              class="argusStatisticRow__field"
              type="text"
              v-model="thirdMonthPreviousYearValue"
              autocomplete="off"
            />
        </div>
        <div class="argusStatistickDashboard__button" @click="addArgusStatistic">
          <span class="text">Save</span>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import './ArgusStatistic.styl'
export default {
    name: 'argus-statistic',
    data: () => ({
        yearToDateTitle: '',
        yearToDateCurrentYearValue: '',
        yearToDatePreviousYearValue: '',
        yearToDateYearBeforePreviousValue: '',
        weeklyTitle: '',
        weeklyCurrentYearValue: '',
        weeklyPreviousYearValue: '',
        weeklyYearBeforePreviousValue: '',
        firstMonthTitle: '',
        firstMonthCurrentYearValue: '',
        firstMonthPreviousYearValue: '',
        secondMonthTitle: '',
        secondMonthCurrentYearValue: '',
        secondMonthPreviousYearValue: '',
        thirdMonthTitle: '',
        thirdMonthCurrentYearValue: '',
        thirdMonthPreviousYearValue: '',
    }),
    async mounted () {
        await this.$store.dispatch('getArgusStatistic');
        this.yearToDateTitle = this.$store.state.argusStatistic.firstTableData[0].name;
        this.yearToDateCurrentYearValue = this.$store.state.argusStatistic.firstTableData[0].col1;
        this.yearToDatePreviousYearValue = this.$store.state.argusStatistic.firstTableData[0].col2;
        this.yearToDateYearBeforePreviousValue = this.$store.state.argusStatistic.firstTableData[0].col3;
        this.weeklyTitle = this.$store.state.argusStatistic.firstTableData[1].name;
        this.weeklyCurrentYearValue = this.$store.state.argusStatistic.firstTableData[1].col1;
        this.weeklyPreviousYearValue = this.$store.state.argusStatistic.firstTableData[1].col2;
        this.weeklyYearBeforePreviousValue = this.$store.state.argusStatistic.firstTableData[1].col3;
        this.firstMonthTitle = this.$store.state.argusStatistic.secondTableData[0].name;
        this.firstMonthCurrentYearValue = this.$store.state.argusStatistic.secondTableData[0].col1;
        this.firstMonthPreviousYearValue = this.$store.state.argusStatistic.secondTableData[0].col2;
        this.secondMonthTitle = this.$store.state.argusStatistic.secondTableData[1].name;
        this.secondMonthCurrentYearValue = this.$store.state.argusStatistic.secondTableData[1].col1;
        this.secondMonthPreviousYearValue = this.$store.state.argusStatistic.secondTableData[1].col2;
        this.thirdMonthTitle = this.$store.state.argusStatistic.secondTableData[2].name;
        this.thirdMonthCurrentYearValue = this.$store.state.argusStatistic.secondTableData[2].col1;
        this.thirdMonthPreviousYearValue = this.$store.state.argusStatistic.secondTableData[2].col2;
    },
    methods: {
      moment,
      async addArgusStatistic () {
        const data = {
            yearToDateTitle: this.yearToDateTitle,
            yearToDateCurrentYearValue: this.tryParseInt(this.yearToDateCurrentYearValue.replaceAll(',', ''), null),
            yearToDatePreviousYearValue: this.tryParseInt(this.yearToDatePreviousYearValue.replaceAll(',', ''), null),
            yearToDateYearBeforePreviousValue: this.tryParseInt(this.yearToDateYearBeforePreviousValue.replaceAll(',', ''), null),
            weeklyTitle: this.weeklyTitle,
            weeklyCurrentYearValue: this.tryParseInt(this.weeklyCurrentYearValue.replaceAll(',', ''), null),
            weeklyPreviousYearValue: this.tryParseInt(this.weeklyPreviousYearValue.replaceAll(',', '')),
            weeklyYearBeforePreviousValue: this.tryParseInt(this.weeklyYearBeforePreviousValue.replaceAll(',', ''), null),
            firstMonthTitle: this.firstMonthTitle,
            firstMonthCurrentYearValue: this.tryParseInt(this.firstMonthCurrentYearValue.replaceAll(',', ''), null),
            firstMonthPreviousYearValue: this.tryParseInt(this.firstMonthPreviousYearValue.replaceAll(',', ''), null),
            secondMonthTitle: this.secondMonthTitle,
            secondMonthCurrentYearValue: this.tryParseInt(this.secondMonthCurrentYearValue.replaceAll(',', ''), null),
            secondMonthPreviousYearValue: this.tryParseInt(this.secondMonthPreviousYearValue.replaceAll(',', ''), null),
            thirdMonthTitle: this.thirdMonthTitle,
            thirdMonthCurrentYearValue: this.tryParseInt(this.thirdMonthCurrentYearValue.replaceAll(',', ''), null),
            thirdMonthPreviousYearValue: this.tryParseInt(this.thirdMonthPreviousYearValue.replaceAll(',', ''), null),
        };
        if (!data.firstMonthCurrentYearValue || !data.firstMonthPreviousYearValue ||
          !data.secondMonthCurrentYearValue || !data.secondMonthPreviousYearValue ||
          !data.thirdMonthCurrentYearValue || !data.thirdMonthPreviousYearValue) {
          const message = {
            title: 'Error',
            text: 'Forecast data must be integer',
          };
          this.$modal.show('ThanksPopup', {message})
          return;
        }
        console.log(data);
        await this.$store.dispatch('addArgusStatistic', data);
        const message = {
            title: 'Agrus data saved',
            text: 'Agrus data saved successfully',
        };
        this.$modal.show('ThanksPopup', {message})
      },
      tryParseInt (str, defaultValue) {
        var retValue = defaultValue;
        if (str !== null) {
         if (str.length > 0) {
            if (!isNaN(str)) {
              retValue = parseInt(str);
            }
         }
        }
        return retValue;
      },
    },
}
</script>